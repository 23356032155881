import * as Yup from "yup";

const flattenFields = (arr) => {
  const result = [];

  function traverse(fields, groupPath = "") {
    fields.forEach((field) => {
      let path = groupPath ? `${groupPath}.${field.id}` : field.id;
      if (field.nested !== "group") {
        result.push({ ...field, path });
      }

      if (field.nested === "group") {
        traverse(field.subfields, path);
      }
    });
  }

  traverse(arr);
  return result;
};

const filedValidation = (field) => {
  let fieldSchema;
  switch (field.type) {
    case "array":
      fieldSchema = Yup.array().of(Yup.string().required(field.errormsg));
      break;
    case "email":
      fieldSchema = Yup.string().email("Invalid email").required(field.errormsg);
      break;
    case "number":
      fieldSchema = Yup.number().required(field.errormsg);
      break;
    case "video":
      fieldSchema = Yup.object().nullable().notRequired();
      break;
    default:
      fieldSchema = Yup.string().required(field.errormsg);
      break;
  }
  return fieldSchema;
};

const CreateValidationSchema = (formFields) => {
  let flattenFiled = flattenFields(formFields);

  const schema = flattenFiled.reduce((acc, field) => {
    let fieldSchema;
    if (!field.nested && field.validated) {
      if (field.dependOn) {
        fieldSchema = Yup.string().when(field.dependOn.name, {
          is: field.dependOn.value,
          then: () => filedValidation(field),
          otherwise: () => Yup.string(),
        });
      } else {
        fieldSchema = filedValidation(field);
      }
    }

    if (field.nested === "array") {
      const subfieldsSchema = CreateValidationSchema(field.subfields);
      fieldSchema = Yup.array().of(subfieldsSchema);
    } else if (field.nested === "object") {
      const subfieldsSchema = CreateValidationSchema(field.subfields);
      fieldSchema = subfieldsSchema;
    } else if (field.nested === "group") {
      let filed = flattenFields(field.subfields);
      const subfieldsSchema = CreateValidationSchema(filed);
      fieldSchema = subfieldsSchema;
    }

    if (field.nested !== "group") {
      return { ...acc, [field.name]: fieldSchema };
    } else {
      return fieldSchema.fields;
    }
  }, {});
  return Yup.object().shape(schema);
};

export default CreateValidationSchema;

// const field = {
//   id: "title1",
//   name: "title1",
//   label: "Title1",
//   validated: true,
//   type: "text",
//   depenOn: {
//     name: "select",
//     value: "yesterday",
//   },
//   errormsg: "Title1 is required",
// };

// // Define the validation schema
// const validationSchema = Yup.object().shape({
//   [field.name]: Yup.string().when(field.depenOn.name, {
//     is: (val) => val == field.depenOn.value,
//     then: (schema) => schema.required(field.errormsg),
//   }),
// });

// // Usage example
// const data = {
//   title1: "sss",
//   select: "yesterday",
// };

// // Validate the data
// validationSchema
//   .validate(data)
//   .then((validatedData) => {
//     console.log("Data is valid:", validatedData);
//   })
//   .catch((error) => {
//     console.log("Validation error:", error);
//   });
