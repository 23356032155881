import { t } from "i18next";

export const getNavigationLinks = () => [
  {
    label: t("Schema"),
    destination: "/",
  },
  ...(process.env.ENV === "dev" ? [{ label: t("Form Preview"), destination: "/form-preview" }] : []),
  {
    label: t("Price"),
    destination: "/pricing",
  },
  {
    label: t("Faqs"),
    destination: "/faqs",
  },
  {
    label: t("Settings"),
    destination: "/settings",
  },
  // {
  //   label: t("mocks.navigation.Other"),
  //   destination: "/Other",
  // },
  // {
  //   label: t("mocks.navigation.Feedback"),
  //   destination: "/feedback",
  // },
];
