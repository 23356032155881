import React from "react";
import {
  BlockStack,
  Box,
  Card,
  InlineGrid,
  InlineStack,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonThumbnail,
  Text,
} from "@shopify/polaris";

export default function CommonSkeletonPage() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={1} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export function DashboardSkeleton({ isEnabled }) {
  const hideReview = localStorage.getItem("ProductReview");
  const hideOtherApps = localStorage.getItem("ProductOtherApps");
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            {!isEnabled ? (
              <Card>
                <div className="dash-banner-skeleton">
                  <div className="dash-banner-enable">
                    <SkeletonThumbnail size="extraSmall" />
                    <SkeletonBodyText lines={1} />
                  </div>
                  <br />
                  <div className="dash-banner-message">
                    <SkeletonBodyText lines={1} />
                    <SkeletonDisplayText size="small" />
                  </div>
                  {/* <br /> */}
                </div>
              </Card>
            ) : null}
            <div className="dash-inline-button">
              <InlineStack gap="300">
                <SkeletonDisplayText size="small" />
                <SkeletonDisplayText size="small" />
                <SkeletonDisplayText size="small" />
              </InlineStack>
            </div>
            <Card>
              <div className="table-skeleton">
                <InlineStack align="space-between">
                  <div className="table-no">
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div className="table-product--name">
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div className="table-visibility">
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div className="table-action">
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div className="table-status">
                    <SkeletonBodyText lines={1} />
                  </div>
                </InlineStack>
              </div>
              <br />
              <div className="table-data">
                <SkeletonBodyText lines={5} />
              </div>
            </Card>
            {!hideReview && (
              <Card>
                <div className="review-skeleton">
                  <div className="review-title">
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div className="review-description">
                    <SkeletonBodyText lines={2} />
                  </div>
                </div>
                <br />
                <div className="review-button">
                  <SkeletonDisplayText size="extraLarge" />
                </div>
              </Card>
            )}

            {!hideOtherApps && (
              <Card>
                <div className="product-other-apps">
                  <InlineStack align="space-between">
                    <div className="product--other-apps--title">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div className="product-discard">
                      <SkeletonBodyText lines={1} />
                    </div>
                  </InlineStack>
                </div>
                <br />
                <div className="otherApp">
                  <Card>
                    <InlineStack wrap={false} gap={500} align="start">
                      <div style={{ display: "flex", gap: "0.5rem" }}>
                        <SkeletonThumbnail size="large" />
                        <div className="other-app-content">
                          <div className="other-app--name">
                            <SkeletonBodyText lines={2} />
                          </div>
                          <div className="other-app--text">
                            <div className="other-app-text--one">
                              <SkeletonBodyText lines={1} />
                            </div>
                            <div className="other-app-text--two">
                              <SkeletonBodyText lines={1} />
                            </div>
                          </div>
                          <SkeletonDisplayText size="small" />
                        </div>
                      </div>
                    </InlineStack>
                  </Card>
                  <Card>
                    <InlineStack wrap={false} gap={500} align="start">
                      <div style={{ display: "flex", gap: "0.5rem" }}>
                        <SkeletonThumbnail size="large" />
                        <div className="other-app-content">
                          <div className="other-app--name">
                            <SkeletonBodyText lines={2} />
                          </div>
                          <div className="other-app--text">
                            <div className="other-app-text--one">
                              <SkeletonBodyText lines={1} />
                            </div>
                            <div className="other-app-text--two">
                              <SkeletonBodyText lines={1} />
                            </div>
                          </div>
                          <SkeletonDisplayText size="small" />
                        </div>
                      </div>
                    </InlineStack>
                  </Card>
                </div>
                <br />
                <div className="product-show-more--apps">
                  <SkeletonDisplayText size="small" />
                </div>
              </Card>
            )}
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

const PricePage = ({ elements, isPremium }) => {
  return (
    <>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "16px",
                height: process.env.ISFREEAPP == 1 ? "100%" : "43.5rem",
              }}
            >
              <Card>
                <div style={{ width: "18rem" }}>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ width: !isPremium ? "3rem" : "5rem" }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {!isPremium ? (
                        <div className="pricing-SkeletonThumbnail">
                          <SkeletonThumbnail size="extraSmall" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    <div className="pricing-SkeletonBodyText">{/* <SkeletonBodyText lines={1} /> */}</div>
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ width: "3rem" }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {isPremium ? (
                        <div style={{ width: "8rem" }}>
                          <SkeletonBodyText lines={1} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    {isPremium ? (
                      <div
                        className="pricing-SkeletonBodyText premium-page--skeleton"
                        style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                      >
                        <div className="premium-skeleton">
                          <SkeletonBodyText lines={1} />
                        </div>
                        <SkeletonBodyText lines={1} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    {isPremium ? (
                      <div className="premium-skeleton--page">
                        <SkeletonBodyText lines={1} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    <SkeletonBodyText lines={1} />
                    <br />
                    <div>{elements}</div>
                    <br />
                    {process.env.ISFREEAPP == 1 ? (
                      <div className="pricing-SkeletonDisplayText">
                        <SkeletonDisplayText size="medium" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </>
  );
};

export function PricingSkeleton() {
  const elements = [];
  for (let i = 0; i < 9; i++) {
    elements.push(
      <div key={`div-${i}`} style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <div className="pricing-SkeletonThumbnail">
          <SkeletonThumbnail size="extraSmall" />
        </div>
        <div style={{ width: "10rem" }}>
          <SkeletonBodyText lines={1} />
        </div>
      </div>
    );
    if (i !== 8) {
      elements.push(<br key={`br-${i}`} />);
    }
  }
  let isPremium = true;
  return (
    <>
      <SkeletonPage primaryAction>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", flexWrap: "wrap" }}>
          <PricePage elements={elements} />
          {process.env.ISFREEAPP == 0 ? <PricePage elements={elements} isPremium={isPremium} /> : ""}
        </div>
      </SkeletonPage>
    </>
  );
}
