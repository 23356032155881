export const isEmptyArray = (a) => Array.isArray(a) && a.every(isEmptyArray);
export const objectToQueryParams = (obj) => {
  const queryParams = Object.entries(obj)
    .filter(([key, value]) => value !== null && value !== undefined && value !== "")
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");

  return queryParams ? `?${queryParams}` : "";
};

export const handleError = (err) => {
  if (typeof err === "string") {
    return err;
  } else if (err?.response?.data?.message) {
    return err?.response?.data?.message;
  } else {
    return "Something went wrong!";
  }
};

export const getValueFromNestedObject = (obj, keyString) => {
  let keys = keyString.split(".");
  let value = obj;

  for (let i = 0; i < keys.length; i++) {
    if (value[keys[i]] !== undefined) {
      value = value[keys[i]];
    } else {
      value = undefined;
      break;
    }
  }

  return value;
};

export const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) return "N/A";
  const dateTime = new Date(dateTimeString);
  if (!dateTime) return "N/A";

  const formattedDate = dateTime?.toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  const formattedTime = dateTime?.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
  const formattedDateTime = formattedDate && formattedTime ? `${formattedDate} at ${formattedTime}` : "N/A";
  return formattedDateTime;
};

export const getValue = (row, valueOption) => {
  if (valueOption.type === "key") {
    return row[valueOption.value];
  }

  if (valueOption.type === "boolean") {
    return row[valueOption.value]?.toString();
  }

  if (valueOption.type === "element") {
    return valueOption.value;
  }

  if (valueOption.type === "date") {
    return formatDateTime(row[valueOption.value]);
  }

  if (valueOption.type === "nestedKey") {
    if (valueOption.value.includes(".")) {
      return valueOption.value.split(".").reduce((acc, prop) => acc && acc[prop], row);
    }

    if (valueOption.value.includes(",")) {
      return valueOption.value.split(",").map((e) => {
        return `${row[e]} ${e} `;
      });
    }
  }
};

export const localStorage = () => {
  try {
    return window?.localStorage;
  } catch (error) {
    return undefined;
  }
};

export const getTrimmedLowercaseValue = (value) => {
  return value?.trim()?.toLowerCase();
};

export function removeBasePriceURL(urlString) {
  try {
    let url = new URL(urlString);

    // Store the parts of the URL we want to keep.
    let paths = "/" + url.pathname.split("/").slice(3).join("/");
    let query = url.search;
    let hash = url.hash;

    return paths + query + hash;
  } catch (error) {
    console.error(error);
  }
}

export function isAdmin() {
  try {
    return localStorage()?.getItem("adminAccessToken");
  } catch (error) {
    console.error(error);
  }
}

export function isAdminPanelAccess() {
  try {
    return localStorage()?.getItem("adminPanelAccessToken");
  } catch (error) {
    console.error(error);
  }
}
export const isObject = (value) => {
  return value !== null && typeof value === "object" && !Array.isArray(value);
};

export const getLocalStorageItem = (variable) => {
  try {
    return localStorage()?.getItem(variable);
  } catch (error) {
    return false;
  }
};
export const setLocalStorageItem = (variable, value) => {
  try {
    localStorage()?.setItem(variable, value);
  } catch (error) {
    return false;
  }
};
export const removeLocalStorageItem = (variable) => {
  try {
    localStorage()?.removeItem(variable);
  } catch (error) {
    return false;
  }
};

export const mergeLanguage = (modifyData, data) => {
  const finalData = { ...data };
  for (const key in modifyData) {
    if (typeof modifyData[key] === "object" && data.hasOwnProperty(key)) {
      finalData[key] = mergeLanguage(data[key], modifyData[key]);
    } else {
      finalData[key] = modifyData[key];
    }
  }
  return finalData;
};

export const adminEnvCheck = (profileData) => {
  try {
    if (
      isAdmin() ||
      new URLSearchParams(location.search).get("token") ||
      process.env.ENV !== "prod" ||
      profileData?.email?.includes("webrexstudio.com")
    ) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const checkPartenerAcc = (profileData) => {
  try {
    if (
      profileData &&
      profileData?.plan_name === "partner_test" &&
      !profileData?.email?.includes("webrexstudio.com") &&
      !profileData?.email?.includes("makasanadarshit")
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
