import { createContext, useEffect, useState } from "react";
import { useAuthenticatedFetch } from "@/Api/Axios";

export const SettingDataContext = createContext();

export const SettingDataContextProvider = ({ children }) => {
  const [isDataNull, setIsDataNull] = useState(false);
  const [isAnimationNull, setIsAnimationNull] = useState(false);
  const [openSection, setOpenSection] = useState("");
  const [mode, setMode] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [productId, setProductId] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [active, setActive] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);

  const date = new Date();
  date.setDate(date.getDate() + 2);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
  const [settingData, setSettingData] = useState({
    // showBrand: true,
    visibleOn: [],
    settings: {
      position: "bottom-left",
      visibility: true,
      bottom: "30",
      width: "50",
    },
    content: {
      title: "",
      subTitle: "FREE Shipping Only 3 Left 🤩",
      titleTextSize: 17,
      titleColor: "#ffffff",
      subTitleColor: "#ffffff",
      subTitleSize: 14,
      image:
        "https://cdn.shopify.com/s/files/1/0517/0026/9233/products/5-panel-hat_4ee20a27-8d5a-490e-a2fc-1f9c3beb7bf5.jpg?v=1606812951",
      imgBorderRadius: 10,
      btnName: "Shop Now",
      productLink: "https://dakaasapp.myshopify.com/products/5-panel-hat",
      duration: 2,
    },
    timerData: {
      type: "flipFlop",
      countDownDate: formattedDate,
      color: "#000000",
      textColor: "#f4f0f0",
    },
    customCss: "",
  });
  const [initialData, setInitialData] = useState({
    // showBrand: true,
    visibleOn: [],
    settings: {
      position: "bottom-left",
      visibility: true,
      bottom: 30,
      width: 50,
    },
    content: {
      title: "",
      subTitle: "FREE Shipping Only 3 Left 🤩",
      titleTextSize: 17,
      titleColor: "#ffffff",
      subTitleColor: "#ffffff",
      subTitleSize: 14,
      image:
        "https://cdn.shopify.com/s/files/1/0517/0026/9233/products/5-panel-hat_4ee20a27-8d5a-490e-a2fc-1f9c3beb7bf5.jpg?v=1606812951",
      imgBorderRadius: 10,
      btnName: "Shop Now",
      productLink: "https://dakaasapp.myshopify.com/products/5-panel-hat",
      duration: 2,
    },
    timerData: {
      type: "flipFlop",
      countDownDate: formattedDate,
      color: "#000000",
      textColor: "#f4f0f0",
    },
    customCss: "",
  });
  const [isEditData, setIsEditData] = useState(null);
  const [isAnimationEdit, setIsAnimationEdit] = useState(null);
  const [animationSettings, setAnimationSettings] = useState({
    animationIn: "fadeInLeft",
    animationOut: "fadeOutRight",
    animationTime: 1300,
    autoplayTime: 2000,
    startTime: 2000,
    borderRadius: 10,
    bgColor: "linear-gradient(to right, rgb(252, 92, 125), rgb(106, 130, 251))",
    showBrand: true,
  });
  const [initialAnimation, setInitialAnimation] = useState({
    animationIn: "fadeInLeft",
    animationOut: "fadeOutRight",
    animationTime: 1300,
    autoplayTime: 2000,
    startTime: 2000,
    borderRadius: 10,
    bgColor: "linear-gradient(to right, rgb(252, 92, 125), rgb(106, 130, 251))",
    showBrand: true,
  });
  const [isAnimationData, setIsAnimationData] = useState();

  const [products, setProducts] = useState([]);
  const fetch = useAuthenticatedFetch();
  useEffect(() => {
    const getAllProductsData = async () => {
      try {
        const response = await fetch.get("/syncProducts");
        const data = response;
        setProducts(data);
      } catch (error) {
        console.log("Unable to get product Data:", error);
      }
    };
    getAllProductsData();
  }, []);

  return (
    <SettingDataContext.Provider
      value={{
        settingData,
        setSettingData,
        apiData,
        setApiData,
        products,
        setProducts,
        isDataNull,
        setIsDataNull,
        animationSettings,
        setAnimationSettings,
        openSection,
        setOpenSection,
        mode,
        setMode,
        productId,
        setProductId,
        showNotification,
        setShowNotification,
        initialData,
        setInitialData,
        active,
        setActive,
        selectedProduct,
        setSelectedProduct,
        isAnimationNull,
        setIsAnimationNull,
        isEditData,
        setIsEditData,
        isAnimationEdit,
        setIsAnimationEdit,
        initialAnimation,
        setInitialAnimation,
        isAnimationData,
        setIsAnimationData,
      }}
    >
      {children}
    </SettingDataContext.Provider>
  );
};
