import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BlockStack, Button, Icon, InlineStack, Modal } from "@shopify/polaris";
import { LockIcon } from "@shopify/polaris-icons";
import { navigate } from "@/Components/Common/NavigationMenu";
import Pricing from "@/Pages/Pricing/Pricing";
import { ProfileContext } from "../../Context/ProfileContext";

export function PremiumBadge({ children }) {
  const { profileData } = useContext(ProfileContext);
  const { t } = useTranslation();

  if (profileData?.recurringPlanType !== "Free") {
    return <div>{children}</div>;
  } else {
    return <Badge tone="critical">{t("common.Premium.Premium")}</Badge>;
  }
}

export function PremiumButton({
  children,
  title,
  subTitle,
  unlockTitle,
  isWrappedWithinButton = true,
  redirectToPricing = false,
}) {
  const [activePremiumModal, setActivePremiumModal] = useState(false);
  const { profileData } = useContext(ProfileContext);
  const setNavigate = navigate();
  const { t } = useTranslation();

  const handleUnlockClick = useCallback(() => {
    if (redirectToPricing) setNavigate("/pricing");
    else setActivePremiumModal(!activePremiumModal);
  }, [activePremiumModal]);

  if (profileData?.recurringPlanType !== "Free") {
    return <div>{children}</div>;
  } else {
    return (
      <React.Fragment>
        {isWrappedWithinButton ? (
          <Button icon={LockIcon} onClick={handleUnlockClick}>
            {t("common.Premium.Unlock")} {unlockTitle}
          </Button>
        ) : (
          <span onClick={handleUnlockClick}>
            <InlineStack blockAlign="center">
              <Icon source={LockIcon} tone="base" />
              &nbsp; {t("common.Premium.Unlock")} {unlockTitle}
            </InlineStack>
          </span>
        )}
        <Modal size="large" titleHidden={true} open={activePremiumModal} onClose={handleUnlockClick}>
          <Modal.Section>
            <BlockStack gap="200" inlineAlign="start"></BlockStack>
            <Pricing
              hasBillingButton={true}
              title={title ? title : t("common.Premium.Unlock advanced features!")}
            />
          </Modal.Section>
        </Modal>
      </React.Fragment>
    );
  }
}
