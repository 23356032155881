import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  BlockStack,
  Button,
  ButtonGroup,
  Icon,
  IndexTable,
  InlineStack,
  Link,
  Modal,
  Page,
  Text,
  Tooltip,
} from "@shopify/polaris";
import { ChatIcon, ClipboardIcon, EditIcon, StarFilledIcon, ViewIcon } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { getCountryCallingCode } from "libphonenumber-js";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { activePlanformFields, activePlaninitialValues } from "@/Assets/Mocks/ActivePlan.mock";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { getLocalStorageItem } from "@/Utils/Index";
import { getFilterField } from "../../Assets/Mocks/User.mock";
import UpdateUserButton from "./UpdateUserButton";

function User() {
  const navigate = useNavigate();
  const childRef = useRef();
  const fetch = useAuthenticatedFetch();
  const activePlanFormRef = useRef();
  const [removePlan, setRemovePlan] = useState(false);
  const [activePlan, setActivePlan] = useState(false);
  const [data, setData] = useState();
  const [copied, setCopied] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [formType, setFormType] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState();

  const getTrialDays = (rows) => {
    const trial = {
      days: rows?.trial_days,
      start: !rows?.trial_start ? new Date() : rows?.trial_start,
    };
    const dt2 = new Date();
    const dt1 = new Date(trial.start);
    const remainingDays =
      trial.days -
      Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
    return remainingDays > 0 ? remainingDays : 0;
  };
  const renewalDays = (row) => {
    const userPayDate = new Date(row?.trial_start);

    if (isNaN(userPayDate)) {
      // Handle the case where userPayDate is not a valid Date
      return 0;
    }
    const trialDays = row?.trial_days || 0;
    const remaningdays = Math.floor((new Date() - userPayDate) / (1000 * 60 * 60 * 24)) - trialDays;
    const remainingDaysMod30 = remaningdays % 30;
    const remainingDaysMod30Minus30 = 30 - remainingDaysMod30;

    return remainingDaysMod30Minus30;
  };

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
  };

  const handleButtonClick = async (row) => {
    setReviewData(row?.reviewRequest?.reviewMeta);
    setOpenReviewModal(true);
  };

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    const accessToken = getLocalStorageItem("adminPanelAccessToken");
    if (!accessToken) {
      navigate("/admin/login");
    }
  }, []);

  const handleIconClick = (phoneNumber, countryCode) => {
    const callingCode = getCountryCallingCode(countryCode);
    const whatsappLink = `https://wa.me/${callingCode}${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  const openpopup = useCallback((row) => {
    setData(row);
    setRemovePlan(true);
  });

  const openActivePlan = useCallback((row) => {
    setData(row);
    setActivePlan(true);
  });

  const ActivePlanhandleSubmit = useCallback(
    async (value) => {
      const response = await fetch.put(`admin/addPlan`, { ...value, shopUrl: data.shopUrl });
      setActivePlan(false);
      childRef.current.fetchData();
    },
    [data]
  );

  const handleRemovePlan = useCallback(async () => {
    setData(data);
    await fetch.put(`admin/removePlan`, { ...data });
    setRemovePlan(false);
    childRef.current.fetchData();
  });
  const activePlansubmitForm = useCallback(() => {
    if (activePlanFormRef.current) {
      activePlanFormRef.current.handleSubmit();
    }
  }, []);

  const handleAccess = useCallback(async (row) => {
    const apiResult = await fetch.get(`admin/access_token?shopUrl=${row.shopUrl}`);
    const url = `${process.env.SHOPIFY_APP_URL}/?token=${apiResult.data.token}`;
    window.open(url, "_blank");
  }, []);

  const handleUpdateUser = useCallback(async (row, form) => {
    setOpenModel(true);
    setFormType(form);
    setCurrentUser(row);
  }, []);

  const handleRemoveDakaas = async (row) => {
    // console.log(">>>", row.shopUrl);
    // console.log(`https://social-app.dakaas.com/apps/api/admin/removebrand?shopUrl=${row.shopUrl}`);
    try {
      const response = await fetch.get(`/admin/removebrand?shopUrl=${row.shopUrl}`);
      console.log(">>>", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = useCallback(() => setRemovePlan(!removePlan), [removePlan]);
  const handleCloseActivePlan = useCallback(() => setActivePlan(!activePlan), [activePlan]);
  const handleCloseReviewModal = useCallback(() => setOpenReviewModal(!openReviewModal), [openReviewModal]);

  const filterFormFields = useMemo(() => getFilterField(), []);

  const ArchivedData = useCallback((rows) => {
    if (rows?.length <= 0) return [];
    return rows?.map((row, index) => {
      const featureStatus = row?.featureStatus;
      const feedbackArray = featureStatus ? Object.keys(featureStatus) : [];
      const filteredItems = feedbackArray.filter((item) => item !== "brokenLinksEnableDate");
      return (
        <IndexTable.Row id={row._id} key={row._id} position={index}>
          <IndexTable.Cell>{index + 1}</IndexTable.Cell>
          <IndexTable.Cell key={row._id + 1}>
            <BlockStack gap="100">
              <Link url={`https://${row.shopUrl}`} target="_blank">
                {row?.storeName}
              </Link>
              <InlineStack gap="200">
                <Text>{row?.shopUrl}</Text>
                <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(row.shopUrl)}>
                  <Icon source={ClipboardIcon} />
                </a>
              </InlineStack>
              <Text>{row?.shop_owner}</Text>
              <BlockStack gap="100">
                {Array.isArray(row?.feedback) &&
                  row?.feedback?.map((item, index) => <b key={index}> {item.id}</b>)}
              </BlockStack>
              {/* <BlockStack gap="100">
                {Array.isArray(row?.markets) && (
                  <Tooltip
                    content={
                      <BlockStack>
                        {row?.markets?.map((item, index) => (
                          <b key={index}>
                            {item.name} : {item.country}
                          </b>
                        ))}
                      </BlockStack>
                    }
                  >
                    <Text variant="bold"> Total Markets : {row?.markets?.length}</Text>
                  </Tooltip>
                )}
              </BlockStack> */}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100">
              <Text>{row?.country_name}</Text>
              <Text>Currency:{row?.currency}</Text>
              <InlineStack gap="200">
                <Text>{row?.email}</Text>
                <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(row.email)}>
                  <Icon source={ClipboardIcon} />
                </a>
              </InlineStack>

              {row.phone && (
                <InlineStack gap="200">
                  <Text>{row.phone}</Text>
                  <a style={{ cursor: "pointer" }} onClick={() => handleIconClick(row.phone, row.country_code)}>
                    <Icon source={ChatIcon} />
                  </a>
                </InlineStack>
              )}
              <Text>Product Count : {row?.productCount}</Text>
              <Text>Installed: {formatTimeAgo(row?.created)}</Text>
              {row.created_at ? <Text>Created : {formatTimeAgo(row?.created_at)}</Text> : null}
              <Text>lastLogin:{formatTimeAgo(row?.lastLogin)}</Text>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100">
              <Text>App Plan : {row?.recurringPlanName}</Text>m,mm
              <Text>Free Trial: {getTrialDays(row)} days</Text>
              <Text>Shopify Plan : {row?.plan_display_name}</Text>
              <Text>renewal:{renewalDays(row)} days</Text>
              {row.trial_start ? <Text>Premium : {formatTimeAgo(row.trial_start)}</Text> : null}
              <Text>
                Onboard:
                {row.isOnBoardingDone ? <Badge tone="success">Y</Badge> : <Badge tone="warning">N</Badge>}
              </Text>
              <Text>
                Shopify Payments:
                {row.eligible_for_payments ? (
                  <Badge tone="success">Y</Badge>
                ) : row.eligible_for_payments == false ? (
                  <Badge tone="warning">N</Badge>
                ) : (
                  <> - </>
                )}
              </Text>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100" key="statusKey">
              {filteredItems.map((item, index) => (
                <Badge key={index} tone={featureStatus[item] === false ? "critical" : "success"}>
                  {item}
                </Badge>
              ))}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <Button onClick={() => handleAccess(row)}>Access</Button>
              <Button variant="primary" onClick={() => handleUpdateUser(row, "update")}>
                <InlineStack gap="100">
                  <Icon source={EditIcon} tone="base" />
                  <Text variant="bodyMd">Update</Text>
                </InlineStack>
              </Button>
              <Button variant="primary" onClick={() => handleUpdateUser(row, "view")}>
                <InlineStack gap="100">
                  <Icon source={ViewIcon} tone="base" />
                  <Text variant="bodyMd">View</Text>
                </InlineStack>
              </Button>

              <Button variant="primary" onClick={() => handleRemoveDakaas(row)}>
                Remove Dakaas
              </Button>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <ButtonGroup>
              <div>
                <Tooltip
                  content={
                    <InlineStack gap="200">
                      {!row.reviewRequest?.isReviewPosted ? "Not reviewed" : "Open Review"}
                    </InlineStack>
                  }
                >
                  <Button
                    variant="primary"
                    tone={!row.reviewRequest?.isReviewPosted ? "critical" : "success"}
                    onClick={() => (row.reviewRequest?.isReviewPosted ? handleButtonClick(row) : null)}
                  >
                    R
                  </Button>
                </Tooltip>
              </div>

              {row.recurringPlanName === "Free" ? (
                <div>
                  <Tooltip content={<InlineStack gap="200">Active Plan</InlineStack>}>
                    <Button variant="primary" onClick={() => openActivePlan(row)}>
                      P
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <Tooltip content={<InlineStack gap="200">Remove Plan</InlineStack>}>
                    <Button tone="critical" onClick={() => openpopup(row)}>
                      P
                    </Button>
                  </Tooltip>
                </div>
              )}
            </ButtonGroup>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });
  }, []);

  return (
    <Page fullWidth>
      <BlockStack gap="500">
        <CommonTable
          resourceName={{
            singular: "User",
            plural: "Users",
          }}
          title="User"
          queryPlaceholder="Search User by (shopUrl, email, storeName, recurringPlanId, recurringPlanName)"
          url={`admin/user`}
          selectable={false}
          rowsData={ArchivedData}
          isFilterVisible
          headings={[
            { title: "No" },
            { title: "Name" },
            { title: "Email" },
            { title: "Plan" },
            { title: "status" },
            { title: "Access" },
            { title: "Actions" },
          ]}
          searchKey={["storeName", "shopUrl", "email", "recurringPlanId", "recurringPlanName"]}
          ref={childRef}
          isAdd={false}
          verticalAlign="center"
          columnContentTypes={["text", "text", "numeric", "numeric", "numeric", "text", "text"]}
          filterFormFields={filterFormFields}
          pinnedFilter={["recurringPlanType", "isOnBoardingDone", "reviewRequest", "productCount"]}
        />
        <Modal
          open={removePlan}
          title="Are you sure you want to remove plan for this user?"
          onClose={handleClose}
          primaryAction={{
            content: "Yes",
            onAction: handleRemovePlan,
          }}
          secondaryActions={[
            {
              content: "No",
              onAction: handleClose,
            },
          ]}
          size="small"
        ></Modal>
        <Modal
          open={activePlan}
          title="Are you sure you want to Active plan for this user?"
          onClose={handleCloseActivePlan}
          primaryAction={{
            content: "Active Plan",
            onAction: activePlansubmitForm,
          }}
          size="small"
        >
          <Modal.Section>
            <CommonForm
              onSubmit={ActivePlanhandleSubmit}
              formRef={activePlanFormRef}
              initialValues={activePlaninitialValues}
              formFields={activePlanformFields}
              title="Active Plan"
              isSave={false}
            />
          </Modal.Section>
        </Modal>
      </BlockStack>
      {openModel && (
        <UpdateUserButton
          currentUser={currentUser}
          formType={formType}
          openModel={openModel}
          setOpenModel={setOpenModel}
          childRef={childRef}
        />
      )}
      {openReviewModal && (
        <Modal
          open={openReviewModal}
          onClose={handleCloseReviewModal}
          title={`${reviewData?.storeName}'s review details`}
        >
          <Modal.Section>
            <BlockStack gap="200">
              <InlineStack wrap={false} gap="400" columns="2">
                <div style={{ width: "110px" }}>
                  <Text as="p" variant="bodyMd" fontWeight="bold">
                    Review
                  </Text>
                </div>
                <div style={{ width: "calc(100% - 110px)" }}>
                  <Text as="p" variant="bodyMd">
                    {reviewData?.review}
                  </Text>
                </div>
              </InlineStack>
              <InlineStack wrap={false} gap="400" columns="2">
                <div style={{ width: "110px" }}>
                  <Text as="p" variant="bodyMd" fontWeight="bold">
                    Rating
                  </Text>
                </div>
                <div style={{ width: "calc(100% - 110px)" }}>
                  <InlineStack align="start">
                    {Array.from({ length: reviewData?.rating }, (_, index) => (
                      <div key={index} style={{ width: "20px", height: "20px" }}>
                        <Icon source={StarFilledIcon} tone="success" />
                      </div>
                    ))}
                    {Array.from({ length: 5 - reviewData?.rating }, (_, index) => (
                      <div key={index} style={{ width: "20px", height: "20px" }}>
                        <Icon key={index} source={StarFilledIcon} tone="secondary" />
                      </div>
                    ))}
                  </InlineStack>
                </div>
              </InlineStack>

              <InlineStack wrap={false} gap="400" columns="2">
                <div style={{ width: "110px" }}>
                  <Text as="p" variant="bodyMd" fontWeight="bold">
                    Date
                  </Text>
                </div>
                <div style={{ width: "calc(100% - 110px)" }}>
                  <Text as="p" variant="bodyMd">
                    {reviewData?.date}
                  </Text>
                </div>
              </InlineStack>
              <InlineStack wrap={false} gap="400" columns="2">
                <div style={{ width: "110px" }}>
                  <Text as="p" variant="bodyMd" fontWeight="bold">
                    Reviewed After
                  </Text>
                </div>
                <div style={{ width: "calc(100% - 110px)" }}>
                  <Text as="p" variant="bodyMd">
                    {reviewData?.reviewedAfter}
                  </Text>
                </div>
              </InlineStack>
            </BlockStack>
          </Modal.Section>
        </Modal>
      )}
    </Page>
  );
}

export default User;
